exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-consent-tsx": () => import("./../../../src/pages/account-consent.tsx" /* webpackChunkName: "component---src-pages-account-consent-tsx" */),
  "component---src-pages-book-a-chat-tsx": () => import("./../../../src/pages/book-a-chat.tsx" /* webpackChunkName: "component---src-pages-book-a-chat-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cybersecurity-is-more-than-confidentiality-tsx": () => import("./../../../src/pages/cybersecurity-is-more-than-confidentiality.tsx" /* webpackChunkName: "component---src-pages-cybersecurity-is-more-than-confidentiality-tsx" */),
  "component---src-pages-ftc-safeguards-explained-tsx": () => import("./../../../src/pages/ftc-safeguards-explained.tsx" /* webpackChunkName: "component---src-pages-ftc-safeguards-explained-tsx" */),
  "component---src-pages-get-tech-tips-tsx": () => import("./../../../src/pages/get-tech-tips.tsx" /* webpackChunkName: "component---src-pages-get-tech-tips-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-msp-pricing-tsx": () => import("./../../../src/pages/msp-pricing.tsx" /* webpackChunkName: "component---src-pages-msp-pricing-tsx" */),
  "component---src-pages-multifactor-authentication-explained-tsx": () => import("./../../../src/pages/multifactor-authentication-explained.tsx" /* webpackChunkName: "component---src-pages-multifactor-authentication-explained-tsx" */),
  "component---src-pages-nist-cybersecurity-framework-explained-tsx": () => import("./../../../src/pages/nist-cybersecurity-framework-explained.tsx" /* webpackChunkName: "component---src-pages-nist-cybersecurity-framework-explained-tsx" */),
  "component---src-pages-orig-index-tsx": () => import("./../../../src/pages/orig-index.tsx" /* webpackChunkName: "component---src-pages-orig-index-tsx" */),
  "component---src-pages-package-comparison-tsx": () => import("./../../../src/pages/package-comparison.tsx" /* webpackChunkName: "component---src-pages-package-comparison-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

